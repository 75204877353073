import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"


const Page = () => (
  <Layout>
    <SEO title="Terms of Use"/>
    <section className="overview" style={{textAlign: "justify"}}>
      <div className="container">
        <h1>Terms of Use</h1>
        <p>Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the
          https://humainly.com website operated by WebMonks VZW.</p>
        <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with
          these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>
        <p><strong>By accessing or using the Service you agree to be bound by these Terms. If you disagree
          with any part of the terms then you may not access the Service.</strong></p>

        <h4>Termination</h4>
        <p>We may terminate or suspend access to our Service immediately, without prior notice or liability, for
          any reason whatsoever, including without limitation if you breach the Terms.
          All provisions of the Terms which by their nature should survive termination shall survive
          termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and
          limitations of liability.</p>

        <h4>Links To Other Web Sites</h4>
        <p>Our Service may contain links to third-party web sites or services that are not owned or controlled
          by WebMonks VZW.</p>

        <p>WebMonks VZW has no control over, and assumes no responsibility for, the content,
          privacy policies, or practices of any third party web sites or services. You further acknowledge and
          agree that WebMonks VZW shall not be responsible or liable, directly or indirectly, for any
          damage or loss caused or alleged to be caused by or in connection with use of or reliance on any
          such content, goods or services available on or through any such web sites or services.</p>

        <h4>Changes</h4>
        <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a
          revision is material we will try to provide at least 30 (change this) days' notice prior to any new terms
          taking effect. What constitutes a material change will be determined at our sole discretion.</p>

        <h4>Contact Us</h4>
        <p>If you have any questions about these Terms, please contact us.</p>
      </div>
    </section>
  </Layout>
)

export default Page
